import {Box, styled} from '@mui/system'
import React from 'react'
import colors from '../colors'


const CompanyImage = styled('div')(
  ({theme}) => `
  box-sizing: border-box;
  border-radius: 28px;
  border: 5px solid ${colors.background[4]};
  background: ${colors.background.white};
  background-size: cover;
  width: 55px;
  height: 55px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`,
)


export default function CompanyImageIcon(props: any): React.JSX.Element {
  if(!props.src || props.src.length === 0){
    return (<></>)
  }

  return (
    <Box>
      <CompanyImage  style={props?.style ?? {}} sx={{backgroundImage: `url('${props.src}')`, backgroundSize: 'cover', height: props.height, width: props.width}}>
      </CompanyImage>

    </Box>
  )
}
