import React from 'react'
import { styled } from '@mui/system'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import colors from '../../colors'
import {SecureIcon} from '../../ui/icons'


const CardContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '20px',
  border: '1px solid #ddd',
  borderRadius: '8px',
  backgroundColor: '#fff',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  maxWidth: '790px',
})

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '15px',
})

const HeaderTitle = styled('text')({
  margin: 0,
  marginRight: 48,
  fontWeight: 400,
  fontSize: '16px',
  color: colors.evergreen[130],
})

const Button = styled('button')({
  width: 450,
  padding: '18px 12px',
  backgroundColor: '#2f4f4f',
  color: '#fff',
  borderRadius: '90px',
  fontSize: '14px',
  fontWeight: 'bold',
  border: 'none',
  cursor: 'pointer',
  transition: 'background-color 0.3s ease',
  '&:hover': {
    backgroundColor: colors.evergreen[100],
  },
})

const BenefitList = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '10px',
})

const BenefitItem = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  gap: '10px',
  fontSize: '14px',
  color: '#2f4f4f',
})

const IconWrapper = styled('div')({
  color: '#2f4f4f',
})

const CardContainerComponent = (props: any) => {
  const benefits = [
    '14-day free trial',
    'See who’s viewed and clicked your profile links',
    'Request intros from verified strategic angel investors and VCs',
    'Access fundraising content & education',
    'Build a beautiful data room for your fundraise',
  ]

  return (
    <CardContainer>
      <Header>
        <SecureIcon style={{marginRight: 5}}/>
          <HeaderTitle>Take your fundraise to the next level with Cherub Raise
          See who’s viewed your profile and track the links they’ve clicked</HeaderTitle>
        <Button onClick={props.onClickButton}>Try Cherub Raise for free</Button>
      </Header>
      <div style={{ border: `1.5px solid ${colors.background[3]}`, marginBottom: 20 }}/>
      <BenefitList>
        {benefits.map((benefit, index) => (
          <BenefitItem key={index}>
            <IconWrapper>
              <CheckCircleIcon fontSize="small" />
            </IconWrapper>
            {benefit}
          </BenefitItem>
        ))}
      </BenefitList>
    </CardContainer>
  )
}

export default CardContainerComponent
