import React from 'react'
import {styled} from '@mui/system'
import {ProfileKind, useListDirectoryTagsQuery} from '../../api/types'
import colors from '../../colors'
import {SelectionArrayT} from '../../Directory'
import FilterGroupComponent from './filter-group'
import FilterButton from './filter-group/FilterButton'
import {useFilterContext} from './Filters.context'
import {formatTagsByCategory} from './helper'

interface CompanyDirectoryProps {
  isMobile: boolean
  isInvestorTab: boolean
}

const sortOptions: SelectionArrayT[] = [
  {name: 'Recommended', slug: 'recommended'},
  {name: 'Newest', slug: 'newest'},
  // {name: 'Most viewed', slug: 'most-viewed'},
  // {name: 'A-Z', slug: 'a-z'},
  // {name: 'Z-A', slug: 'z-a'},
]

const SelectionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  overflowX: 'auto',
  paddingTop: 30,
  maxWidth: '100%',
  scrollbarColor: `${colors.darkEvergreen[80]} transparent`,
})

const Container = styled('div')({
  fontFamily: 'Aktiv-Grotesk',
  width: '100%',
})

const FiltersContainer = styled('div')<{isMobile?: boolean}>(({isMobile}) => ({
  display: isMobile ? 'block' : 'grid',
  backgroundColor: colors.background[3],
  padding: '30px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
  zIndex: 1000,
  position: isMobile ? 'unset' :'absolute',
  width: isMobile? 'unset' : 'fit-content',
}))

const SelectionArrayStyle = {
  maxWidth: 'fit-content',
  whiteSpace: 'nowrap',
  borderRadius: '20px',
  fontSize: 16,
}

const DirectoryFilter: React.FC<CompanyDirectoryProps> = ({isMobile, isInvestorTab}) => {
  const tags = useListDirectoryTagsQuery({
    variables: {profileType: isInvestorTab ? ProfileKind.Investor : ProfileKind.Founder},
  })
  const {showFilters, selectionArray, setSelected} = useFilterContext()
  const formatedTags = {
    ...formatTagsByCategory(tags.data?.tags || [], isInvestorTab ? ProfileKind.Investor : ProfileKind.Founder),
    'Sort By': sortOptions,
  }

  const toggleSelected = (opt: SelectionArrayT) => {
    setSelected((prev: SelectionArrayT[]) =>
      selectionArray.some(
        item => item.name === opt.name && item.slug === opt.slug,
      ) ? prev.filter((item: SelectionArrayT) => item.name !== opt.name && item.slug !== opt.slug) : [...prev, opt],
    )
  }

  const toggleClearAll = () => {
    setSelected([])
  }

  return (
    <>
      {showFilters || isMobile ? (
        <Container>
          <FiltersContainer isMobile={isMobile} sx={{gridTemplateColumns: { xs: '1fr', md: 'repeat(4, 1fr)', lg: 'repeat(5, 1fr)' }}}>
            {Object.entries(formatedTags).map(([title, options], index) => (
              <FilterGroupComponent
                key={`${title} + ${index}`}
                title={title}
                options={options as unknown as SelectionArrayT[]}
                isMobile={isMobile}
                isRadio={title === 'Sort By'}
              />
            ))}
          </FiltersContainer>
        </Container>
      ) : null}
      {selectionArray.length ? (
        <SelectionContainer>
          <FilterButton
            key={'clear-all'}
            opt={'Clear All'}
            onClick={() => toggleClearAll()}
            isSelected={false}
            buttonStyle={{padding: '12px 20px' }}
            containerWrapperStyle={{marginBottom: 0}}
            style={{...SelectionArrayStyle, border: `1px solid ${colors.background[5]}`}}
          />
          <div style={{borderLeft: `2px solid ${colors.background[4]}`}} />
          {selectionArray.map((selection, index) => {
            return (
              <FilterButton
                key={`${selection}-${index}`}
                opt={selection.name}
                onClick={() => toggleSelected(selection)}
                isSelected={selectionArray.some(
                  item => item.name === selection.name && item.slug === selection.slug,
                )}
                containerWrapperStyle={{marginBottom: 0}}
                buttonStyle={{padding: '12px 20px' }}
                style={{...SelectionArrayStyle, backgroundColor: colors.background[3]}}
              />
            )
          })}
        </SelectionContainer>
      ) : null}
    </>
  )
}

export default DirectoryFilter
